'use client';

import { useRouter } from 'next/navigation';
import { useSyncExternalStore, useTransition } from 'react';
import { disableDraftMode } from './actions/actions';

const emptySubscribe = () => () => { };

export default function DisableDraftMode() {
    const router = useRouter();
    const [pending, startTransition] = useTransition();

    const shouldShow = useSyncExternalStore(
        emptySubscribe,
        () => window.top === window,
        () => false,
    );

    let className = 'fixed top-0 left-0 z-[1000] w-full border-b rounded-b-100 border-layers-strong bg-layers-foreground transition-opacity duration-default';

    if (pending) {
        className += ' opacity-75 pointer-events-none';
    }

    if (!shouldShow) {
        return null;
    }

    return (
        <div className={className}>
            <div className="container-small mx-auto p-050 text-center type-090">
                {pending ? (
                    'Disabling draft mode...'
                ) : (
                    <>
                        Previewing drafts.{' '}
                        <button
                            type="button"
                            className="type-link"
                            onClick={() => (
                                startTransition(() =>
                                    disableDraftMode().then(() => {
                                        router.refresh();
                                    }),
                                )
                            )}
                        >
                            Disable Draft Mode
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}
